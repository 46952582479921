import styled from "vue-styled-components"

export const Container = styled.div`
    padding: 5px;
    width: 100%;
    display: block;
    @media print {
        margin-top: 5px;
        padding: 3px 5px;
    }
`;

export const MedicineItem =  styled('div', {borderBottom: Boolean})`
    display: flex;
    flex-direction: column;
    padding-bottom: 1px;
    border-bottom: ${props => props.borderBottom ? '1px solid black' : ''};       
    width: 100%;
    margin-bottom: 2px;
    .medicineItem__info {
        display: block;
        padding-bottom: 1px; 
        width: 100%;
        .order {
            font-weight: normal;
            margin-right: 8px;
            float: left;
            font-size: 15px;
        }       
        .name {
            font-weight: bold;
            float: left;
            font-size: 15px;
        }
        .unit {
            float: right;
            font-weight: normal;
            font-size: 15px;
        }      
    }
    .medicine__note {
       font-size: 15px;
    }
    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
        .medicineItem__info {
            .order {
                font-size: 9px;
            }
            .name {
                font-size: 9px;
            }
            .unit {
                font-size: 9px;
            }
        }
        .medicine__note {
            font-size: 9px;
        }
    }
    @media only print and (max-width: 420px) and (min-width: 350px) {
        .medicineItem__info {
            .order {
                font-size: 12px;
            }
            .name {
                font-size: 12px;
            }
            .unit {
                font-size: 12px;
            }
        }
        .medicine__note {
            font-size: 12px;
        }
    }
`;

export const MedicineBox = styled.div`
    display: block;
    padding: 5px;
    margin: 4px 0;
    width: 100%;

    .medicineItem{
        display: block;
        margin-top: 3px;
        flex-direction: column;
        width: 100%;
        .medicineItem__info {
            display: inline-block;
            padding-bottom: 1px;
            border-bottom: 1px solid black;       
             width: 100%;
            .order {
                font-weight: normal;
                margin-right: 8px;
                float: left;
                font-size: 20px;
            }       
            .name {
                font-weight: bold;
                float: left;
                font-size: 20px;
            }
            .unit {
                float: right;
                font-weight: normal;
                font-size: 20px;
            }      
        }
        .medicineItem__text {
            display: inline-block;
            padding-bottom: 1px;  
            width: 100%;
            .note {
                font-weight: normal;
                float: left;
                font-size: 20px;
            }
        }
    }
    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
       .medicineItem {
           .medicineItem__info{
               .name {
                   font-size: 9px;
               }
               .order {
                   font-size: 9px;
               }
               .unit {
                   font-size: 9px;
               }
           }
           .medicineItem__text {
                .note {
                    font-size: 9px;
                }
            }
       }
      
    }
    @media only print and (max-width: 420px) and (min-width: 350px) {
        .medicineItem {
           .medicineItem__info{
               .name {
                   font-size: 12px;
               }
               .order {
                   font-size: 12px;
               }
               .unit {
                   font-size: 12px;
               }
           }
           .medicineItem__text {
                .note {
                    font-size: 12px;
                }
            }
       }
    }
`;

export const DiagnosisBox = styled.div`
    display: block;

    .itemDiagnosis{
        display: inline-block;
        width: 100%;
        .itemDiagnosis__eyeSide {
            display:block;
            font-weight: bold;
        }
    }
    .diagnosis__title {
        font-size: 15px;
    }
    .diagnosisBox__content {
        span {
            font-size: 15px;
        }   
    }

    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
        .diagnosisBox__content {
            span {
                font-size: 8px;
            }  
        }
        .diagnosis__title {
            font-size: 8px;
        }
    }
    @media only print and (max-width: 420px) and (min-width: 350px) {
        .diagnosisBox__content {
            span {
                font-size: 12px;
            } 
       }
       .diagnosis__title {
            font-size: 12px;
        }
    }
`;

export const ShortRefractive = styled.div`
    display: block;
    margin-top: 5px;
`;

export const TableContent = styled.table`
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    th, td {
        border: 0.5px solid black;
    }
    thead {
        th {
            font-weight: bold;

            @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                font-size: 7px;
            }
            @media only print and (max-width: 420px) and (min-width: 350px) {
                font-size: 12px;
            }
            @media only print and (max-width: 1123px) and (min-width: 550px) {
                font-size: 16px;
            }
        }
    }
    tbody {
        tr {
           .title__prescription {
               padding: 5px 15px;
               font-size: 14px;
               text-align: left; 
               font-weight: 420;
               @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                    font-size: 7px;
                    padding: 1px 3px;
                    width: 70px;
               }
               @media only print and (max-width: 420px) and (min-width: 350px) { /* A5 size */
                    font-size: 11px;
                    padding: 2px 5px;
                    width: 90px;
               }
               @media only print and (max-width: 1123px) and (min-width: 550px) {
                    font-size: 15px;
                    width: 150px;
               }
           }
           .content__prescription {
                span{
                    font-size: 14px;
                    .bold__case {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                padding: 4px;

                @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
                    padding: 1px;
                    span {
                        font-size: 9px;
                        .bold__case {
                            font-size: 9px;
                        }
                    }
                }
                @media only print and (max-width: 420px) and (min-width: 350px) {
                    padding: 2px;
                    span {
                        font-size: 12px;
                        .bold__case {
                            font-size: 14px;
                        }
                    }
                }
                @media only print and (max-width: 1123px) and (min-width: 550px) {
                    padding: 2px;
                    span {
                        font-size: 15px;
                        .bold__case {
                            font-size: 15px;
                        }
                    }
                }
           }

           @media print {
               padding: 1px;
           }
        }
        td {
            padding: 3px 0px;
            text-align: center;

            @media print {
                padding: 2px;
            }
        }
    }

    @media print {
        border: 0.5px solid black;
        tr, th, td {
            margin: 0;
            padding: 0;
        }
    }
`;

export const DoctorBox = styled('div', { width: String })`
    display: block;
    width: 100%;

    .doctorBox__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .doctorBox__content__item {
            display: flex; 
            padding: 3px;
            width: 50%;
            margin: 2px 0px;

            .item__title {
                font-size: 17px;
                margin-right: 5px;
            }
            .item__content {
                font-size: 16px;
                margin-left: 5px;
            }
        }
        @media only print and (max-width: 420px) and (min-width: 350px) {
            .doctorBox__content__item {
                .item__title {
                    font-size: 10px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 10px;
                    margin-left: 5px;
                }
            }
        }
        @media only print and (max-width: 1123px) and (min-width: 550px) { 
            .doctorBox__content__item {
                .item__title {    
                    font-size: 15px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
        }

    }
`;

export const TitleItemDischarge = styled('div', {borderBottom: Boolean, marginText: String})`
    display: block;
    margin: 5px 0px;
    border-bottom: ${props => (props.borderBottom) ? '1px solid black' : ''};
    font-weight: bold;
    span {
        font-size: 24px;
        margin: ${props => (props.marginText != '') ? props.marginText : ''}
    }
    @media only print and (max-width: 420px) and (min-width: 350px) { 
        span {
            font-size: 15px;
        }
    }
    @media only print and (max-width: 1123px) and (min-width: 550px) {
        span {
            font-size: 19px;
        }
    }
`;

export const OptometristBox = styled('div')`
    display: block;
    width: 100%;

    .optometristBox__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .optometristBox__content__item {
            display: flex; 
            padding: 3px;
            width: 50%;
            margin: 2px 0px;

            .item__title {
                font-size: 17px;
                margin-right: 5px;
            }
            .item__content {
                font-size: 16px;
                margin-left: 5px;
            }
        }
        @media only print and (max-width: 420px) and (min-width: 350px) {
            .optometristBox__content__item {
                .item__title {
                    font-size: 10px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 10px;
                    margin-left: 5px;
                }
            }
        }
        @media only print and (max-width: 1123px) and (min-width: 550px) { 
            .optometristBox__content__item {
                .item__title {    
                    font-size: 15px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
        }

    }
`;

export const ReferralBox = styled('div', { width: String })`
    display: block;
    width: 100%;

    .referralBox__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .referralBox__content__item {
            display: flex; 
            padding: 3px;
            width: 50%;
            margin: 2px 0px;

            .item__title {
                font-size: 17px;
                margin-right: 5px;
            }
            .item__content {
                font-size: 16px;
                margin-left: 5px;
            }
        }
        @media only print and (max-width: 420px) and (min-width: 350px) {
            .referralBox__content__item {
                .item__title {
                    font-size: 10px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 10px;
                    margin-left: 5px;
                }
            }
        }
        @media only print and (max-width: 1123px) and (min-width: 550px) { 
            .referralBox__content__item {
                .item__title {    
                    font-size: 15px;
                    margin-right: 5px;
                }
                .item__content {
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
        }

    }
`;


export const Footer = styled.div`
    margin: 5px 8px;
    
    .footer__note {
        font-size: 18px;
        height: 50px;
    }
    .footer__reExam {
        margin-top: 3px;
        height: 50px;
    }
    .text__note {
        margin-top: 2px;
        font-size: 14px;
    }
    .note__title {
        font-size: 15px;
        font-weight: bold;
    }
    .note__warning {
        margin-top: 15px;
        display: block;
        text-align: center;
        font-size: 12px;
    }
    .footer__staffHandler {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .footer__staffHandler__date {
            font-size: 15px;
            .bold__case {
                font-weight: bold;
            }
        }
        .footer__staffHandler__role {
            font-size: 15px;
        }
        .footer__staffHandler__signal {
            height: 60px;
        }
        .footer__staffHandler__name {
            font-weight: bold;
            font-size: 15px;
        }
    }
    .footer__remind__patient {
        font-size: 15px;
    }
    /* @media print {
        .text__note {
            font-size: 11px;
        }
        .footer__note { 
            font-size: 12px;
        }
        .optometrist__name {
            font-size: 13px;
        }
        .doctor__name {
            font-size: 11px;
            text-transform: uppercase;
        }
    } */
    @media only print and (max-width: 298px) and (min-width: 150px) { /* A6 size */
       .note__title {
           font-size: 9px;
       }
       .text__note {
           font-size: 8px;
       }
       .footer__staffHandler {
            .footer__staffHandler__date {
                font-size: 9px;
                .bold__case {
                    font-size: 8px;
                }
            }
            .footer__staffHandler__role {
                font-size: 9px;
            }
            .footer__staffHandler__signal {
                height: 40px;
            }
            .footer__staffHandler__name {
                font-size: 9px;
            }
       }
       .footer__remind__patient {
           font-size: 9px;
       }
    }

    @media only print and (max-width: 420px) and (min-width: 350px) { /* A5 size */
        .note__title {
           font-size: 12px;
        }
        .text__note {
           font-size: 11px;
        }
        .footer__staffHandler {
            .footer__staffHandler__date {
                font-size: 11px;
                .bold__case {
                    font-size: 10px;
                }
            }
            .footer__staffHandler__role {
                font-size: 12px;
            }
            .footer__staffHandler__signal {
                height: 60px;
            }
            .footer__staffHandler__name {
                font-size: 11px;
            }
       }
       .footer__remind__patient {
           font-size: 12px;
       }
    }
`;