import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'
var httpClient = new HttpClient(ApiGateway.Emr);

export default {
  async SearchListDiagnosis(keySearch = "", page = 1, limit = 10) {
    var query = {
      keySearch,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get("/Diagnosis/Search", query, header);
  },
  async SearchListICDDx(keySearch = "", statusID = 1, page = 1, limit = 10) {
    var query = {
      keySearch,
      statusID,
    }
    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get("/ICDDx/Search", query, header);
  },
  async CreateDiagnosisWithListICDD(bodyData) {
    return httpClient.post("/Diagnosis/CreateWithListICCDxID", {}, bodyData);
  },
  async GetDiagnosisPrimaryDetail(episodeRecordID, position) {
    var query = {
      episodeRecordID,
      position,
    }
    return httpClient.get("/Diagnosis/GetDiagnosisPrimaryDetail", query);
  },
  async DeleteDiagnosisByID(diagnosisID) {
    var query = {
      diagnosisID,
    }
    return httpClient.delete("/Diagnosis/Delete/{diagnosisID}", query);
  },
}