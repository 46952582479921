<template>
  <Container>
    <DiagnosisBox>
      <div class="d-flex flex-row">
        <div class="d-block text-left diagnosis__title pl-2">Chuẩn đoán:</div>
        <div class="d-block text-left ml-2">
          <div
            v-if="checkDuplicatePrimary(primarys) === 'none'"
            class="d-block diagnosisBox__content"
          >
            <span style="w-100 d-block font-weight-bold"
              >{{ $t("examination.dischargeSummary.noDiagnosis") }}
            </span>
          </div>
          <div
            v-if="checkDuplicatePrimary(primarys) === 'Duplicate'"
            class="d-block diagnosisBox__content"
          >
            <div class="d-flex flex-column mb-3">
              <span class="w-100 d-block">
                <b class="ml-3"> Cả 2 mắt: </b>
                <b>{{ dataPrimarys.icdDxCode }}</b> -
                {{ dataPrimarys.icdDxDescription }}
              </span>
            </div>
          </div>
          <div
            v-if="checkDuplicatePrimary(primarys) === 'noDuplicate'"
            class="d-block diagnosisBox__content"
          >
            <span class="w-100 d-block font-weight-bold">
              Mắt phải: {{ diagnosisBySide(1) }}
            </span>
            <span class="w-100 d-block font-weight-bold">
              Mắt trái: {{ diagnosisBySide(2) }}
            </span>
          </div>
        </div>
      </div>
    </DiagnosisBox>

    <MedicineBox v-if="listMedication.length > 0">
      <MedicineItem
        v-for="(item, index) in listMedication"
        :key="index"
        :borderBottom="index != listMedication.length - 1"
      >
        <div class="medicineItem__info">
          <span class="order">{{ index + 1 }}./</span>
          <span class="name">{{ item.medicineName }}</span>
          <span class="unit">{{ item.buying }}</span>
        </div>
        <span class="medicine__note">{{ item.note }}</span>
        <span class="medicine__note">{{ item.instruction }}</span>
      </MedicineItem>
    </MedicineBox>

    <ShortRefractive>
      <TableContent>
        <thead>
          <tr>
            <th colspan="2"></th>
            <th colspan="3">Mắt phải</th>
            <th colspan="3">Mắt trái</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" class="title__prescription">Thị lực xa</td>
            <td class="title__prescription pa-2">Không kính</td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.od.visualAcuity.sc }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.os.visualAcuity.sc }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-left pa-2 title__prescription">Có kính</td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.od.newGlass.va }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.os.newGlass.va }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">Nhãn áp</td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.od.tonometer.iop }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ optometryConclusion.os.tonometer.iop }}</span>
            </td>
          </tr>
        </tbody>
      </TableContent>
    </ShortRefractive>
    <Footer>
      <v-row justify="space-between" class="w-100">
        <v-col cols="7" class="d-flex flex-column">
          <div class="d-block w-100">
            <span class="note__title">Dặn dò:</span>
            <div class="d-flex w-100 flex-column">
              <span
                class="text__note"
                v-for="(item, index) in medicationNote"
                :key="index"
                >{{ item }}<br
              /></span>
            </div>
          </div>
          <div class="d-block w-100 mt-2">
            <span class="note__title">Tái khám:</span>
            <div class="d-flex w-100 flex-column">
              <span
                class="text__note"
                v-for="(item, index) in dateFollowUp"
                :key="index"
              >
                {{ formatFollowUp(item.timeStart, item.note) }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="pa-2">
          <div class="footer__staffHandler">
            <span class="footer__staffHandler__date"
              >Ngày: <b class="bold__case">{{ today }}</b></span
            >
            <span class="footer__staffHandler__role">Bác sĩ</span>
            <div class="footer__staffHandler__signal"></div>
            <span class="footer__staffHandler__name">{{
              lastUserInserting
            }}</span>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-block text-center footer__remind__patient">
            <span
              >* Vui lòng mang theo toa thuốc và đơn kính khi đến tái khám</span
            >
          </div>
        </v-col>
      </v-row>
    </Footer>
  </Container>
</template>

<script>
import { awaitAll } from "@/plugins/helper";
import moment from "moment";
import _ from "lodash";
import { MedicineBuyingUnit } from "@/components/common/Medication/config";
import DiagnosisService from "@/services/diagnosis";
import OptometryConclusionService from "@/services/optometryConclusion";
import MedicationService from "@/services/medication";
import EpisodeRecordService from "@/services/episodeRecord";
import BookingService from "@/services/booking";

import {
  Container,
  DiagnosisBox,
  ShortRefractive,
  TableContent,
  MedicineBox,
  MedicineItem,
  Footer,
} from "./css/style";
export default {
  name: "MedicinePrescription",
  components: {
    Container,
    DiagnosisBox,
    ShortRefractive,
    TableContent,
    MedicineBox,
    MedicineItem,
    Footer,
  },
  computed: {
    userName() {
      let user = this.$store.getters.userData;
      return user.fullName;
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
  },
  data: () => ({
    medicationNote: [],
    dateFollowUp: [],
    listMedication: [],
    primaryDiagnosis: {
      od: [],
      os: [],
    },
    dataPrimarys: {},
    primarys: [],
    optometryConclusion: {
      od: {
        visualAcuity: {
          sc: "",
        },
        newGlass: {
          va: "",
        },
        tonometer: {
          iop: "",
        },
      },
      os: {
        visualAcuity: {
          sc: "",
        },
        newGlass: {
          va: "",
        },
        tonometer: {
          iop: "",
        },
      },
    },
    lastUserInserting: "",
  }),
  mounted() {
    this.print();
  },
  methods: {
    async print() {
      await awaitAll([
        this.getDiagnosis(),
        this.getOptometryConclusion(),
        this.searchingMedication(),
        this.getMedicationNote(),
        this.renderFollowUp(),
      ]);
      setTimeout(() => {
        window.print();
        setTimeout(() => {
          //window.close();
        }, 500);
      }, 500);
    },
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatFollowUp(date, note) {
      if (!date) return "";
      if (!note) note = "N/A";
      return `${this.jsUcfirst(
        moment(date).lang("vi").format("dddd")
      )}, Ngày ${moment(date).format("DD/MM/YYYY")} lúc ${moment(date).format(
        "HH:mm"
      )} : ${note}`;
    },
    async getDiagnosis() {
      const apiResult = await DiagnosisService.SearchListDiagnosis(
        this.$route.params.episodeRecordID,
        1,
        100
      );
      if (apiResult.error) {
        this.$toast.error("Can not retrieve Data!");
        return;
      }
      let listDiagnosis = _.cloneDeep(apiResult.items);
      // parse data
      this.primaryDiagnosis = {
        od: _.filter(listDiagnosis, {
          diagnosisCodePosition: 1,
          diagnosisCode: "Primary",
        }),
        os: _.filter(listDiagnosis, {
          diagnosisCodePosition: 2,
          diagnosisCode: "Primary",
        }),
      };
      this.primarys = apiResult.items.filter(
        (i) => i.diagnosisCode === "Primary"
      );
    },
    checkDuplicatePrimary(array = []) {
      if (array.length > 1) {
        if (array[0].icdDxID == array[1].icdDxID) {
          this.dataPrimarys = array[0];
          return "Duplicate";
        } else {
          return "noDuplicate";
        }
      } else if (array.length == 1) {
        return "noDuplicate";
      } else if (array.length == 0) {
        return "none";
      }
    },
    diagnosisBySide(side) {
      // 1: OD,
      // 2: OS
      let objectDiagnosis;
      switch (side) {
        case 1:
          objectDiagnosis = this.primaryDiagnosis.od;
          break;
        case 2:
          objectDiagnosis = this.primaryDiagnosis.os;
          break;
        default:
          return "";
      }
      if (objectDiagnosis.length == 0) {
        return "";
      }
      objectDiagnosis = objectDiagnosis[0];
      let result = `${objectDiagnosis.icdDxDescription} (${objectDiagnosis.icdDxCode})`;
      return result;
    },

    async getOptometryConclusion() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      let listConclusion = ["TonoPD", "NewGlass", "VisualAcuity"];
      var result = await OptometryConclusionService.getElementConclusion(
        episodeRecordID,
        listConclusion.join(",")
      );

      if (result.error) {
        this.$toast.error("Can not retrieve Optometry Conclusion");
        return;
      }
      this.optometryConclusion.od = result.od;
      this.optometryConclusion.os = result.os;
    },

    async searchingMedication() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      var listMedication = await MedicationService.search(
        episodeRecordID,
        "",
        1,
        100
      );
      if (listMedication.error) {
        this.$toast.error(listMedication.message);
        return "";
      }
      let items = _.cloneDeep(listMedication.items);

      items = items.map((i) => {
        let buyingUnitEntry = _.find(MedicineBuyingUnit, { id: i.buyingUnit });

        i.buying = `${i.totalBuyingNumber} ${
          buyingUnitEntry != null ? buyingUnitEntry.vname : ""
        }`;
        return i;
      });
      this.listMedication = items;
      if (this.listMedication.length > 0) {
        let lastRecord = this.listMedication[this.listMedication.length - 1];
        this.lastUserInserting = lastRecord.userPrescribeName;
      }
    },
    async getMedicationNote() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      var resultData = await EpisodeRecordService.getByID(episodeRecordID);
      if (resultData.error) {
        console.log(
          "Cannot get medication note at the moment! Please try again later."
        );
        return;
      }
        this.medicationNote = resultData.medicationNote.length ? resultData.medicationNote.split(",") : [];
    },
    async renderFollowUp() {
      var apiResult = await BookingService.getBookingByPatient(
        this.$route.params.patientId,
        moment().format("YYYY-MM-DD"),
        ""
      );
      if (!apiResult || apiResult.error) {
        console.log(
          "Cannot get follow up at the moment! Please try again later."
        );
        return;
      }
      this.dateFollowUp = apiResult.items;
    },
  },
};
</script>

<style scoped lang ="scss">
@media print {
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }

  .medicineItem__info {
    padding: 1px;
  }

  .medicineItem__note {
    padding: 0px;
    font-size: 10px;
  }
  .order {
    font-size: 12px;
  }
  .name {
    font-size: 12px;
  }
  .unit {
    font-size: 12px;
  }
  td {
    padding: 1px;
    font-size: 10px;
  }
  .content__prescription {
    font-size: 9px;
  }
  .title__prescription {
    font-size: 9px;
  }
  .diagnosisBox__content {
    font-size: 12px;
    margin-left: 5px;
  }
  .footer__content {
    margin-top: 3px;
  }
}
</style>